export const BLOG_PAGINATION_SELECTED_COLOR = "#FFF";
export const BLOG_PAGINATION_COLOR_GREY = "#666";
export const BLOG_ACTIVE_COLOR = "#00ECBD";
export const BLOG_DARK_COLOR_BLACK = "#000000";
export const WEBSITE_ORIGIN = "https://globaldatanet.com";

export const blogsTitle = ["/cloud-insights", "/news", "/tech-blog"];

export const checkNameOfBlog = (blogName: string) => {
  blogName = blogName.replace("/", "");

  switch (blogName) {
    case "cloud-insights":
      return "Cloud insights";
    case "news":
      return "GDN News";
    default:
      return "Tech blog";
  }
};

export type BlogTable = {
  _key: string;
  _type: string;
  cells: string[];
};

export type BlogTypeLink = {
  blank: boolean;
  href: string;
  _key: string;
  _type: string;
};

export type NavTag = {
  title: string;
};
