import React, { FC, useContext, useEffect, useState } from "react";
import * as styles from "./SingleCaseStudies.module.scss";
import { ModalProps } from "../../Shared/MobileModal/MobileModal";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import { GatsbyImage } from "gatsby-plugin-image";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import RenderBenefits from "./RenderBenefits";
import { ImageUrlCheck } from "../../Shared/ImageUrlCheck/ImageUrlCheck";
import { UseImageUrl } from "../../hooks/UseImageUrl";
import HeroBanner from "../../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../../Shared/HeroBannerMobile/HeroBannerMobile";
import cx from "classnames";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import { LanguageContext } from "../../../context/languageContext";
import translate from "../../../i18n/translate";
import { BLOG_ACTIVE_COLOR } from "../../BlogUiComponents/BlogsMain/BlogsMain.helper";
import ContactForm from "../../../components/Shared/ContactUiComponents/ContactForm";
const BlockContent = require("@sanity/block-content-to-react");
type Props = {
  data: any;
};

const SingleCaseStudies: FC<Props & ModalProps> = ({ data }) => {
  const { language } = useContext(LanguageContext);
  const [savedLanguage, setSavedLanguage] = useState("");
  const width = UseWindowSize();
  const [activeBenefit, setActiveBenefit] = useState(0);
  const [testimonialVideoLink, setTestimonialVideoLink] = useState("");
  const [benefits] = useState([
    data.benefit1Title,
    data.benefit2Title,
    data.benefit3Title,
  ]);

  const [benefitsDE] = useState([
    data.benefit1TitleDE,
    data.benefit2TitleDE,
    data.benefit3TitleDE,
  ]);

  UseImageUrl(data.challengeImage, data._rawChallengeImage);
  UseImageUrl(data.solutionImage, data._rawSolutionImage);
  UseImageUrl(data.benefitsImage, data._rawBenefitsImage);
  UseImageUrl(data.testimonialImage, data._rawTestimonialImage);
  UseImageUrl(data.aboutImage, data._rawAboutImage);

  useEffect(() => {
    setSavedLanguage(language);
  }, [language]);

  useEffect(() => {
    if (savedLanguage === "DE") {
      const link =
        data.testimonialVideoLink + "?&cc_load_policy=1&cc_lang_pref=de";
      setTestimonialVideoLink(link);
    } else {
      setTestimonialVideoLink(data.testimonialVideoLink);
    }
  }, [savedLanguage]);

  return (
    <div>
      {width.width <= 1200 ? (
        <HeroBannerMobile
          heading={language === "EN" ? data.title : data.titleDE}
          description={data.subtitle}
          homeBackground={ImageUrlCheck(data._rawMainImage)}
          buttonText={translate("home-contactus")}
          date=""
          link="/contact"
          caseStudyDescription={
            language === "EN"
              ? data.caseStudyDescription
              : data.caseStudyDescriptionDe
          }
          alt="case studies"
        />
      ) : (
        <HeroBanner
          heading={language === "EN" ? data.title : data.titleDE}
          description={data.subtitle}
          homeBackground={ImageUrlCheck(data._rawMainImage)}
          buttonText={translate("home-contactus")}
          date=""
          link="/contact"
          caseStudyDescription={
            language === "EN"
              ? data.caseStudyDescription
              : data.caseStudyDescriptionDe
          }
        />
      )}

      <InnerWrapper>
        <div className={styles.singleCaseContentBlock}>
          <div
            className={cx(
              styles.singleCaseChallenge,
              styles.singleCaseChallengeFirst
            )}
          >
            <div className={styles.singleCaseImageBox}>
              <GatsbyImage
                image={data?.challengeImage?.asset?.gatsbyImageData}
                alt={data?.altChallengeImage || "SingleCaseStudies"}
                className={styles.caseChallengeImage}
              />
            </div>
            <div className={styles.singleCaseTextBox}>
              <div>
                <h2>.{translate("case-studies-challenge")}</h2>
                {language === "EN" ? (
                  <BlockContent blocks={data.challenge} serializers={{}} />
                ) : (
                  <BlockContent blocks={data.challengeDE} serializers={{}} />
                )}
              </div>
              <CircleOutline
                isBlurVisible={false}
                initialScale={0.3}
                animateScale={1}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.contactOutlineCircle}
              />
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.5}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleOverflowText}
              />
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.5}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleLeft}
              />
              <CircleBlur
                initialScale={0.1}
                animateScale={1}
                initialOpacity={0}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroWhiteBlurCirce}
              />
            </div>
          </div>

          <div
            className={cx(
              styles.singleCaseSolution,
              styles.singleCaseSolutionMobile
            )}
          >
            <div className={styles.singleCaseTextBox}>
              <div>
                <h2>.{translate("case-studies-solution")}</h2>
                {language === "EN" ? (
                  <BlockContent blocks={data.solution} serializers={{}} />
                ) : (
                  <BlockContent blocks={data.solutionDE} serializers={{}} />
                )}
              </div>
              <CircleOutline
                isBlurVisible={false}
                initialScale={0.3}
                animateScale={1}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.contactOutlineCircle}
              />
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.5}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleOverflowText}
              />
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.5}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleLeft}
              />
              <CircleBlur
                initialScale={0.1}
                animateScale={1}
                initialOpacity={0}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroWhiteBlurCirce}
              />
            </div>
            <div
              className={cx(
                styles.singleCaseImageBox,
                styles.singleCaseImageBoxSecond
              )}
            >
              <GatsbyImage
                image={data?.solutionImage?.asset?.gatsbyImageData}
                alt={
                  data?.altSolutionImage || "Single Case Study Solution Case"
                }
                className={styles.caseChallengeImage}
              />
            </div>
          </div>

          <div className={styles.singleCaseChallenge}>
            <div
              className={cx(
                styles.singleCaseImageBox,
                styles.singleCaseImageBoxForth
              )}
            >
              <GatsbyImage
                image={data?.benefitsImage?.asset?.gatsbyImageData}
                alt={data?.altBenefitsImage || "Single Case Study Benefit"}
                className={styles.caseChallengeImage}
              />
            </div>
            <div className={styles.singleBenefitTextBox}>
              <div>
                <h2>.{translate("case-studies-benefits")}</h2>
                {language === "EN" ? (
                  <>
                    <ul>
                      {benefits.map((benefit, index) => (
                        <li
                          key={index}
                          onClick={() => setActiveBenefit(index)}
                          style={
                            index === activeBenefit
                              ? { color: "#00ecbd" }
                              : null
                          }
                        >
                          {benefit}
                        </li>
                      ))}
                    </ul>
                    <RenderBenefits
                      language={"EN"}
                      data={data}
                      activeBenefit={activeBenefit}
                    />
                  </>
                ) : (
                  <>
                    <ul>
                      {benefitsDE.map((benefit, index) => (
                        <li
                          key={index}
                          onClick={() => setActiveBenefit(index)}
                          style={
                            index === activeBenefit
                              ? { color: BLOG_ACTIVE_COLOR }
                              : null
                          }
                        >
                          {benefit}
                        </li>
                      ))}
                    </ul>
                    <RenderBenefits
                      language={"DE"}
                      data={data}
                      activeBenefit={activeBenefit}
                    />
                  </>
                )}
              </div>
              <CircleOutline
                isBlurVisible={false}
                initialScale={0.3}
                animateScale={1}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.contactOutlineCircleBenfits}
              />
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.5}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleLeftBenfits}
              />
            </div>
          </div>

          {data.testimonialVideoLink && (
            <div className={styles.singleCaseVideoSection}>
              <h2>.Testimonial</h2>
              <div className={styles.singleCaseTestimonialVideo}>
                <iframe
                  className={styles.testimonialVideo}
                  src={testimonialVideoLink}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )}

          {data.testimonialName && !data.testimonialVideoLink ? (
            <div className={styles.singleCaseTestimonial}>
              <h2>.Testimonial</h2>
              <div className={styles.singleCaseTestimonialInner}>
                <GatsbyImage
                  image={data?.testimonialImage?.asset?.gatsbyImageData}
                  alt={
                    data?.altTestimonialImage ||
                    "Single Case Study Testimonial Image"
                  }
                  className={styles.caseTestimonialImage}
                />
                <div className={styles.testimonialContent}>
                  <h4>
                    {language === "EN"
                      ? data.testimonialName
                      : data.testimonialNameDE}
                  </h4>
                  <p className={styles.testimonialName}>
                    {language === "EN"
                      ? data.testimonialPosition
                      : data.testimonialPositionDE}
                  </p>
                  <p className={styles.testimonialBody}>
                    {language === "EN"
                      ? data.testimonialBody
                      : data.testimonialBodyDE}
                  </p>

                  <CircleBlur
                    initialScale={0.1}
                    initialOpacity={0}
                    animateScale={1.5}
                    animateOpacity={0.15}
                    transitionType={"easeIn"}
                    transitionDelay={0.2}
                    transitionDuration={1}
                    circleStyleClass={styles.heroBackgroundInnerCircleLeft}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div
            className={cx(
              styles.singleCaseChallenge,
              styles.singleCaseTextBoxLast
            )}
            style={
              data.testimonialName === null
                ? { flexDirection: "row-reverse" }
                : null
            }
          >
            {data?.aboutImage?.asset?.gatsbyImageData && (
              <div className={styles.singleCaseImageBox}>
                <GatsbyImage
                  image={data?.aboutImage?.asset?.gatsbyImageData}
                  alt={data?.altAboutImage || "Single Case Study About Image"}
                  className={styles.caseChallengeImage}
                />
              </div>
            )}
            <div className={styles.singleCaseTextBox}>
              <div>
                <h2>{data?.altAboutImage}</h2>
                {language === "EN" ? (
                  <BlockContent blocks={data.about} serializers={{}} />
                ) : (
                  <BlockContent blocks={data.aboutDE} serializers={{}} />
                )}
              </div>
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.5}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleOverflowText}
              />
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.5}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleLeft}
              />
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.5}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleRight}
              />
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.5}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleLeft2}
              />
              <CircleBlur
                initialScale={0.1}
                animateScale={1}
                initialOpacity={0}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroWhiteBlurCirce}
              />
            </div>
          </div>
        </div>
        <div className={styles.contacts}>
          <ContactForm heading={translate("contact-caseStudies")} isCaseStudy />
        </div>
      </InnerWrapper>
    </div>
  );
};

export default SingleCaseStudies;
