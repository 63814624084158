import imageUrlBuilder from "@sanity/image-url";

const sanityClient = require("@sanity/client"); //for talking with david on monday
const client = sanityClient({
  projectId: "828ol6gw",
  dataset: "production",
  apiVersion: "2021-08-14",
  useCdn: true,
});
const builder = imageUrlBuilder(client);

export const ImageSeoUrlCheck = (source: any) => {
  const seo = process.env.IMAGE_PATH + "seo/";
  return builder
    .image(source)
    .url()
    .replace("cdn.sanity.io/images/828ol6gw/production/", seo)
    .replace("png", "jpg");
};
