// extracted by mini-css-extract-plugin
export var application = "SingleCaseStudies-module--application--BIK+2";
export var applicationHeading = "SingleCaseStudies-module--applicationHeading--CshW9";
export var applicationMap = "SingleCaseStudies-module--applicationMap--o34q2";
export var approach = "SingleCaseStudies-module--approach--gkBYh";
export var backgroundCircles = "SingleCaseStudies-module--backgroundCircles--sE14Z";
export var banner = "SingleCaseStudies-module--banner--howcZ";
export var benefits = "SingleCaseStudies-module--benefits--bbxsp";
export var bottomButton = "SingleCaseStudies-module--bottomButton--IqEju";
export var careerBtnApplyNow = "SingleCaseStudies-module--careerBtnApplyNow--5Nnzw";
export var careers = "SingleCaseStudies-module--careers--VmRsK";
export var caseChallengeImage = "SingleCaseStudies-module--caseChallengeImage--btwF1";
export var caseTestimonialImage = "SingleCaseStudies-module--caseTestimonialImage--Jl+cW";
export var contact = "SingleCaseStudies-module--contact--hboef";
export var contactButton = "SingleCaseStudies-module--contactButton--rGUJd";
export var contactOutlineCircle = "SingleCaseStudies-module--contactOutlineCircle--Ldide";
export var contactOutlineCircleBenfits = "SingleCaseStudies-module--contactOutlineCircleBenfits--73TLN";
export var coreValue = "SingleCaseStudies-module--coreValue--RWBir";
export var coreValueColumbLeft = "SingleCaseStudies-module--coreValueColumbLeft--oIB26";
export var coreValueColumbRight = "SingleCaseStudies-module--coreValueColumbRight--eeNGl";
export var coreValueItem = "SingleCaseStudies-module--coreValueItem--1Seb3";
export var coreValueItemBox = "SingleCaseStudies-module--coreValueItemBox--KE8Yl";
export var flowContainer = "SingleCaseStudies-module--flowContainer--UeO7G";
export var flowItem = "SingleCaseStudies-module--flowItem--DB53i";
export var flowItemDay = "SingleCaseStudies-module--flowItemDay--V14Wk";
export var flowItemDayMobile = "SingleCaseStudies-module--flowItemDayMobile--sNYDi";
export var flowItemDayMobileContainer = "SingleCaseStudies-module--flowItemDayMobileContainer--qQxh0";
export var flowItemMobile = "SingleCaseStudies-module--flowItemMobile--LqIsQ";
export var flowLeftContainer = "SingleCaseStudies-module--flowLeftContainer--Xi47c";
export var flowRightContainer = "SingleCaseStudies-module--flowRightContainer--lSKrw";
export var flowRightContainerMobile = "SingleCaseStudies-module--flowRightContainerMobile--DVh4R";
export var flowStop = "SingleCaseStudies-module--flowStop--8ERAG";
export var followUs = "SingleCaseStudies-module--followUs---6dDq";
export var gdnPromotePic = "SingleCaseStudies-module--gdnPromotePic--Y9DLK";
export var heading = "SingleCaseStudies-module--heading--hCojX";
export var heroBackgroundCircleLeft = "SingleCaseStudies-module--heroBackgroundCircleLeft--oPnXH";
export var heroBackgroundInnerCircleGreen = "SingleCaseStudies-module--heroBackgroundInnerCircleGreen--6t4r3";
export var heroBackgroundInnerCircleLeft = "SingleCaseStudies-module--heroBackgroundInnerCircleLeft--fbFHO";
export var heroBackgroundInnerCircleLeft2 = "SingleCaseStudies-module--heroBackgroundInnerCircleLeft2--fIoHE";
export var heroBackgroundInnerCircleLeftBenfits = "SingleCaseStudies-module--heroBackgroundInnerCircleLeftBenfits--QxTUS";
export var heroBackgroundInnerCircleOverflowText = "SingleCaseStudies-module--heroBackgroundInnerCircleOverflowText--PW9cR";
export var heroBackgroundInnerCircleRight = "SingleCaseStudies-module--heroBackgroundInnerCircleRight--KdwwT";
export var heroBannerMainContainer = "SingleCaseStudies-module--heroBannerMainContainer----gpk";
export var heroWhiteBlurCirce = "SingleCaseStudies-module--heroWhiteBlurCirce--vP3bU";
export var igImageGroup1 = "SingleCaseStudies-module--igImageGroup1--T2Ezd";
export var igImageGroup1Item = "SingleCaseStudies-module--igImageGroup1Item--gx9Qq";
export var igImageGroup2 = "SingleCaseStudies-module--igImageGroup2--x2QRz";
export var igImageGroup2Btm = "SingleCaseStudies-module--igImageGroup2Btm--8pToi";
export var igImageGroup2BtmItem = "SingleCaseStudies-module--igImageGroup2BtmItem--fteJs";
export var igImageGroup2Item = "SingleCaseStudies-module--igImageGroup2Item--oLNUy";
export var inNumberColumbLeft = "SingleCaseStudies-module--inNumberColumbLeft--4rx+t";
export var inNumberColumbRight = "SingleCaseStudies-module--inNumberColumbRight--TPTZi";
export var inNumbersImage = "SingleCaseStudies-module--inNumbersImage--mS9iM";
export var inNumbersItem = "SingleCaseStudies-module--inNumbersItem--ZeS34";
export var innumbers = "SingleCaseStudies-module--innumbers--nsN8+";
export var insights = "SingleCaseStudies-module--insights--yrTfZ";
export var instaContainer = "SingleCaseStudies-module--instaContainer--LUrzy";
export var instaLeftBox = "SingleCaseStudies-module--instaLeftBox--gywzB";
export var instaRightBox = "SingleCaseStudies-module--instaRightBox--6gP4R";
export var introDescriptionButtonSection = "SingleCaseStudies-module--introDescriptionButtonSection--rhcrD";
export var introImageSection = "SingleCaseStudies-module--introImageSection--+fXys";
export var introSectionCircleItem = "SingleCaseStudies-module--introSectionCircleItem--Fk5Ds";
export var introSectionContainer = "SingleCaseStudies-module--introSectionContainer--dpIhG";
export var introSectionText = "SingleCaseStudies-module--introSectionText--n9tCn";
export var keyTopicsImage = "SingleCaseStudies-module--keyTopicsImage--UTbOz";
export var knowUs = "SingleCaseStudies-module--knowUs--FnhQZ";
export var knowUsImage = "SingleCaseStudies-module--knowUsImage--CGJdQ";
export var knowUsText = "SingleCaseStudies-module--knowUsText--yvr0+";
export var pillars = "SingleCaseStudies-module--pillars--XFCL1";
export var reportBtn = "SingleCaseStudies-module--reportBtn--nLqwA";
export var reportBtnGerman = "SingleCaseStudies-module--reportBtnGerman--0yFMo";
export var singleBenefitTextBox = "SingleCaseStudies-module--singleBenefitTextBox--pJi1e";
export var singleCaseChallenge = "SingleCaseStudies-module--singleCaseChallenge--AEhV6";
export var singleCaseChallengeFirst = "SingleCaseStudies-module--singleCaseChallengeFirst--7Hl+F";
export var singleCaseContentBlock = "SingleCaseStudies-module--singleCaseContentBlock--EpD1D";
export var singleCaseImageBox = "SingleCaseStudies-module--singleCaseImageBox--GRqos";
export var singleCaseImageBoxForth = "SingleCaseStudies-module--singleCaseImageBoxForth--IyzLw";
export var singleCaseImageBoxSecond = "SingleCaseStudies-module--singleCaseImageBoxSecond--2rxIk";
export var singleCaseSolution = "SingleCaseStudies-module--singleCaseSolution--mYLfv";
export var singleCaseSolutionMobile = "SingleCaseStudies-module--singleCaseSolutionMobile--PJUIK";
export var singleCaseTestimonial = "SingleCaseStudies-module--singleCaseTestimonial--xiH9r";
export var singleCaseTestimonialInner = "SingleCaseStudies-module--singleCaseTestimonialInner--GIN1j";
export var singleCaseTestimonialVideo = "SingleCaseStudies-module--singleCaseTestimonialVideo--idOnX";
export var singleCaseTextBox = "SingleCaseStudies-module--singleCaseTextBox--b4iDp";
export var singleCaseTextBoxLast = "SingleCaseStudies-module--singleCaseTextBoxLast--Xly7L";
export var singleCaseVideoSection = "SingleCaseStudies-module--singleCaseVideoSection--tUZrf";
export var testimonialBody = "SingleCaseStudies-module--testimonialBody--RC4Nq";
export var testimonialContent = "SingleCaseStudies-module--testimonialContent--hfBKr";
export var testimonialName = "SingleCaseStudies-module--testimonialName--0WPhE";
export var testimonialVideo = "SingleCaseStudies-module--testimonialVideo--ycPZS";
export var torchWrapper = "SingleCaseStudies-module--torchWrapper--i+YwI";
export var verticalLine = "SingleCaseStudies-module--verticalLine--BJwW2";
export var verticalLineMobile = "SingleCaseStudies-module--verticalLineMobile--gUFCh";
export var yourBenefits = "SingleCaseStudies-module--yourBenefits--NRP-q";
export var yourBenefitsIcon = "SingleCaseStudies-module--yourBenefitsIcon--zCxYW";
export var yourBenefitsInner = "SingleCaseStudies-module--yourBenefitsInner--p7+ka";
export var yourBenefitsItem = "SingleCaseStudies-module--yourBenefitsItem--sLi57";