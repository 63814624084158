import React, {
  FC,
  MouseEvent,
  ReactElement,
  useContext,
  useState,
} from "react";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";
import { motion } from "framer-motion";
import * as styles from "./HeroBanner.module.scss";
import CircleOutline from "../CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../CircleOutlineAndBlur/CircleBlur";
import TorchImage from "../TorchImage/TorchImage";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import CustomButton from "../CustomButton/CustomButton";
import { FiCalendar, FiClock } from "react-icons/fi";
import moment from "moment";
import momenttz from "moment-timezone";
import CircleGreenBlur from "../../Shared/CircleOutlineAndBlur/Circlegreenblur";
import { FaLinkedinIn } from "react-icons/fa";
import CustomReportsButton from "../CustomButton/CustomReportsButton";
import { LanguageContext } from "../../../context/languageContext";
import ReportHeroFrom from "../../ReportsUiComponents/ReportHeroForm/ReportHeroFrom";
const BlockContent = require("@sanity/block-content-to-react");

type Props = {
  heading: string | ReactElement;
  description: string | ReactElement;
  homeBackground: any;
  buttonText?: string | ReactElement;
  date?: string;
  link?: string;
  externalLink?: string;
  linkedIn?: string;
  caseStudyDescription?: any;
  isExternalLink?: boolean;
  isImageCentered?: boolean;
  isReport?: boolean;
  isNewReport?: string;
  formIntro?: string;
  submitFormMessage?: string;
};

const HeroBanner: FC<Props> = ({
  heading,
  description,
  homeBackground,
  buttonText,
  date,
  link,
  externalLink,
  linkedIn,
  caseStudyDescription,
  isExternalLink,
  isImageCentered = false,
  isReport = false,
  isNewReport = "No",
  formIntro,
  submitFormMessage,
}) => {
  const [showImageCircle, setShowImageCircle] = useState<boolean>(true);
  const [htmlElementsZindex, setHtmlElementsZindex] = useState<boolean>(false);
  const { width, height } = UseWindowSize();
  const { language } = useContext(LanguageContext);

  const moveTorch = (
    event: MouseEvent,
    outOfCircle?: boolean,
    torchRef?: HTMLElement
  ): void => {
    var idTorch: HTMLElement = document.getElementById("torch"); //refactoring needed
    const torchWidth =
      width >= 2500
        ? 600
        : width >= 2000
        ? 530
        : width >= 1200 && height > 950
        ? 380
        : width >= 1200 && height <= 950
        ? 280
        : 380;
    if (typeof window !== undefined) {
      if (
        outOfCircle ||
        event.nativeEvent.offsetX < 150 + width * 0.2 ||
        event.nativeEvent.offsetX + 150 > width ||
        event.nativeEvent.offsetY < 150 ||
        event.nativeEvent.offsetY + 150 > torchRef?.clientHeight
      ) {
        setHtmlElementsZindex(false);
        idTorch.style.clipPath = !isImageCentered
          ? `circle(${torchWidth}px at ${
              width * 0.75 - torchWidth
            }px ${torchWidth}px`
          : `circle(${400}px at ${1150}px ${500}px`;
        idTorch.style.transition = "ease-in clip-path 0.5s";
      } else {
        setHtmlElementsZindex(true);
        idTorch.style.clipPath = `circle(150px at ${event.nativeEvent.offsetX}px ${event.nativeEvent.offsetY}px)`;
        idTorch.style.transition = "none";
      }
    }
  };

  return (
    <div className={styles.heroBannerMainContainer}>
      <div className={styles.introSectionContainer}>
        <div
          className={styles.introSectionText}
          style={{ zIndex: htmlElementsZindex ? 0 : 10000 }}
        >
          {date && (
            <motion.p>
              <span>
                <FiCalendar color="#00ECBD" />
                &nbsp; {moment(date).format("MMM Do YYYY")}
              </span>

              <span>
                <FiClock color="#00ECBD" />
                &nbsp;At {moment(date).format("HH:mm")} hrs.{" "}
                {momenttz(date).tz("Europe/Berlin").format("z")}
              </span>
            </motion.p>
          )}
          <motion.h1
            style={{
              overflow: "hidden",
              willChange: "height",
              color: "red",
              overflowWrap: "break-word",
              wordWrap: " break-word",
              hyphens: "auto",
              maxWidth: isNewReport !== "Yes" ? "auto" : "69%",
            }}
            initial={{ height: 0, y: 70 }}
            animate={{ height: "auto", y: 0 }}
            exit={{ height: 0, y: 70 }}
            transition={{ type: "easeOut", duration: 1.2 }}
            className={"heading"}
          >
            {heading}
          </motion.h1>
          {caseStudyDescription && (
            <BlockContent blocks={caseStudyDescription} serializers={{}} />
          )}

          <motion.div
            style={{ willChange: "opacity" }}
            initial={{ opacity: 0, visibility: "hidden" }}
            animate={{ opacity: 1, visibility: "visible" }}
            transition={{ ease: "easeIn", duration: 0.9, delay: 1.5 }}
            className={styles.introDescriptionButtonSection}
          >
            <motion.span
              initial={{ visibility: "hidden", scale: 0.7 }}
              animate={{ visibility: "visible", scale: 1 }}
              transition={{ ease: "easeIn", duration: 0.5 }}
            >
              {description}
            </motion.span>
            {linkedIn && (
              <a href={linkedIn} target="_blank" rel="noreferrer">
                <FaLinkedinIn color="#ffff" size={22} />
              </a>
            )}
            {buttonText ? (
              <>
                {!isReport ? (
                  <CustomButton
                    externalLink={externalLink}
                    buttonClass={styles.bottomButton}
                    text={buttonText}
                    linkPath={link}
                    image={arrowRightWhite}
                  />
                ) : (
                  <CustomReportsButton
                    buttonMainClass={
                      language === "EN"
                        ? styles.reportBtn
                        : styles.reportBtnGerman
                    }
                    text={buttonText}
                    linkPath={link}
                    image={arrowRightWhite}
                  />
                )}
              </>
            ) : null}
          </motion.div>
        </div>
        {isNewReport !== "Yes" ? (
          <div className={styles.torchWrapper}>
            <TorchImage
              isAnimationActive={false}
              backgroundImageTorch={homeBackground}
              moveTorch={moveTorch}
              setShowImageCircle={setShowImageCircle}
              showImageCircle={showImageCircle}
              isImageCenterd={isImageCentered}
            />
          </div>
        ) : (
          <div
            style={{
              width: "46%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
              zIndex: 10001,
            }}
          >
            <ReportHeroFrom
              formIntro={formIntro}
              submitFormMessage={submitFormMessage}
            />
          </div>
        )}
      </div>
      <div className={styles.backgroundCircles}>
        <CircleOutline
          isBlurVisible={true}
          initialScale={0.3}
          animateScale={1}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroBackgroundCircleLeft}
        >
          <CircleGreenBlur
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleGreen}
          />
        </CircleOutline>
        <CircleBlur
          initialScale={0.1}
          animateScale={1}
          initialOpacity={0}
          animateOpacity={0.15}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroWhiteBlurCirce}
        />
      </div>
    </div>
  );
};

export default HeroBanner;
