import React, { FC } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import SingleCaseStudies from "../components/CaseStudyUiComponents/SingleCaseStudies/SingleCaseStudies";

import { SEO } from "../components/seo";
import { ImageSeoUrlCheck } from "../components/Shared/ImageSeoUrlCheck/ImageSeoUrlCheck";
type Props = {
  data: any;
};

const CaseSTudyTemplate: FC<Props> = ({ data }) => {
  const caseStudy = data.sanityCaseStudy;

  return (
    <Layout location="/blog">
      <SEO
        title={caseStudy.title}
        description={caseStudy.caseStudyDescription[0].children[0].text}
        type="article"
        image={ImageSeoUrlCheck(data.sanityCaseStudy._rawMainImage.asset.url)}
      />

      <SingleCaseStudies data={caseStudy} />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    sanityCaseStudy(id: { eq: $slug }) {
      _rawAboutImage
      _rawBenefitsImage
      _rawChallengeImage
      _rawMainImage(resolveReferences: { maxDepth: 20 })
      _rawSolutionImage
      _rawTestimonialImage
      id
      about {
        _type
        children {
          _key
          _type
          text
        }
      }
      caseStudyDescription {
        _type
        children {
          _key
          _type
          text
        }
      }
      aboutImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altAboutImage
      challenge {
        _type
        children {
          text
          marks
          _type
          _key
        }
      }
      tags {
        id
      }
      mainImage {
        asset {
          gatsbyImageData(fit: CLIP, placeholder: BLURRED)
        }
      }
      altMainImage
      challengeImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altMainImage
      solution {
        _type
        children {
          text
          marks
          _type
          _key
        }
      }
      solutionImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altSolutionImage
      testimonialName
      testimonialBody
      testimonialPosition
      testimonialImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altTestimonialImage
      benefitsImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altBenefitsImage
      benefit1 {
        _type
        children {
          text
          marks
          _type
          _key
        }
      }
      benefit2 {
        _type
        children {
          text
          marks
          _type
          _key
        }
      }
      benefit3 {
        _type
        children {
          text
          marks
          _type
          _key
        }
      }
      benefit1Title
      benefit2Title
      benefit3Title
      title
      titleDE
      aboutDE {
        _type
        children {
          _key
          _type
          text
        }
      }
      benefit1DE {
        _type
        children {
          _key
          _type
          marks
          text
        }
      }
      benefit2DE {
        _type
        children {
          _key
          _type
          marks
          text
        }
      }
      benefit1TitleDE
      benefit2TitleDE
      benefit3DE {
        _type
        children {
          _key
          _type
          marks
          text
        }
      }
      benefit3TitleDE
      caseStudyDescriptionDe {
        _type
        children {
          _key
          _type
          text
        }
      }
      challengeDE {
        _type
        children {
          _key
          _type
          marks
          text
        }
      }
      solutionDE {
        _type
        children {
          _key
          _type
          marks
          text
        }
      }
      testimonialBodyDE
      testimonialNameDE
      testimonialPositionDE
      testimonialVideoLink
      testimonialVideoLinkDE
    }
  }
`;

export default CaseSTudyTemplate;
