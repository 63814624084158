import { useEffect } from "react";
import { ImageUrlCheck } from "../Shared/ImageUrlCheck/ImageUrlCheck";

export const UseImageUrl = (data: any, replacementUrl: any) => {
  useEffect(() => {
    if (data) {
      if (data.image) {
        data.image.asset.gatsbyImageData.images.fallback.src =
          ImageUrlCheck(replacementUrl);
        data.image.asset.gatsbyImageData.images.fallback.srcSet =
          ImageUrlCheck(replacementUrl);
      } else {
        data.asset.gatsbyImageData.images.fallback.src =
          ImageUrlCheck(replacementUrl);
        data.asset.gatsbyImageData.images.fallback.srcSet =
          ImageUrlCheck(replacementUrl);
      }
    }
  }, [data]);

  return data;
};
