import React, { FC, useRef } from "react";
import { motion } from "framer-motion";
import * as styles from "./TorchImage.module.scss";
import { UseWindowSize } from "../../hooks/UseWindowSize";

type Props = {
  moveTorch: (x: any, y: boolean, ref: any) => void;
  setShowImageCircle: (x: boolean) => void;
  showImageCircle: boolean;
  backgroundImageTorch: string;
  isAnimationActive?: boolean;
  isImageCenterd?: boolean;
};

const TorchImage: FC<Props> = ({
  moveTorch,
  setShowImageCircle,
  showImageCircle,
  backgroundImageTorch,
  isAnimationActive,
  isImageCenterd = false,
}) => {
  const { width, height } = UseWindowSize();
  const torchRef: any = useRef(null);
  const torchWidth =
    width >= 2500
      ? 600
      : width >= 2000
      ? 530
      : width >= 1200 && height > 950
      ? 380
      : width >= 1200 && height <= 950
      ? 280
      : 380;
  return (
    <div
      className={styles.introImageSection}
      style={{ pointerEvents: isAnimationActive ? "none" : "all" }}
    >
      <motion.div
        style={{ willChange: "contents" }}
        ref={torchRef}
        className={styles.introSectionCircleItem}
        onMouseMove={e => {
          moveTorch(e, showImageCircle, torchRef?.current); //after review and talk about animation see if this can be shorten
        }}
        onMouseEnter={e => {
          moveTorch(e, false, torchRef?.current);
          setShowImageCircle(false);
        }}
        onMouseLeave={e => {
          moveTorch(e, true, torchRef?.current);
          setShowImageCircle(true);
        }}
      >
        <motion.div
          style={{
            willChange: "clip-path",
            background: `url(${backgroundImageTorch}) center no-repeat`,
            backgroundSize: "cover",
          }}
          initial={{ clipPath: "circle(0px at 50% 50%)" }}
          animate={{
            clipPath:
              width > 2400 && isImageCenterd
                ? `circle(${400}px at ${1150}px ${500}px`
                : `circle(${torchWidth}px at ${
                    (width || 0) * 0.75 - torchWidth
                  }px ${torchWidth}px`,
          }}
          id="torch"
          className={styles.torch}
          transition={{ type: "easeOut", duration: 1.2 }}
        ></motion.div>
      </motion.div>
    </div>
  );
};

export default TorchImage;
