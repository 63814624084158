import React, { FC } from "react";
const BlockContent = require("@sanity/block-content-to-react");

type Props = {
  data: any;
  activeBenefit: number;
  language?: string;
};

const RenderBenefits: FC<Props> = ({ data, activeBenefit, language }) => {
  switch (activeBenefit) {
    case 1:
      return (
        <>
          <BlockContent
            blocks={language === "EN" ? data.benefit2 : data.benefit2DE}
            serializers={{}}
          />
        </>
      );
    case 2:
      return (
        <>
          <BlockContent
            blocks={language === "EN" ? data.benefit3 : data.benefit3DE}
            serializers={{}}
          />
        </>
      );
    default:
      return (
        <>
          <BlockContent
            blocks={language === "EN" ? data.benefit1 : data.benefit1DE}
            serializers={{}}
          />
        </>
      );
  }
};

export default RenderBenefits;
